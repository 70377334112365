




























































































































import Vue from 'vue';
import { schemas } from '@truck-parking/tp-api';

export default Vue.extend({
  name: 'SignUp',

  data() {
    return {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      company: '',
      password: '',
      passwordRepeat: '',
      checkbox: false,

      firstNameRules: [
        (v: string) => !!v || this.$i18n.t('validation-firstNameRequired'),
        (v: string) => new RegExp(schemas.signup.properties.firstName.pattern).test(v)
          || this.$i18n.t('validation-nameType'),
        (v: string) => (v && v.length <= schemas.signup.properties.firstName.maxLength)
          || this.$i18n.t('validation-nameMax'),
      ],
      lastNameRules: [
        (v: string) => !!v || this.$i18n.t('validation-lastNameRequired'),
        (v: string) => new RegExp(schemas.signup.properties.lastName.pattern).test(v)
          || this.$i18n.t('validation-nameType'),
        (v: string) => (v && v.length <= schemas.signup.properties.lastName.maxLength)
          || this.$i18n.t('validation-nameMax'),
      ],
      emailRules: [
        (v: string) => !!v || this.$i18n.t('validation-emailRequired'),
        (v: string) => new RegExp(schemas.signup.properties.emailAddress.pattern).test(v)
          || this.$i18n.t('validation-emailType'),
        (v: string) => (v && v.length <= schemas.signup.properties.emailAddress.maxLength)
          || this.$i18n.t('validation-emailMax'),
      ],
      phoneRules: [
        (v: string) => !!v || this.$i18n.t('validation-phoneRequired'),
        (v: string) => new RegExp(schemas.signup.properties.phoneNumber.pattern).test(v)
          || this.$i18n.t('validation-phoneType'),
      ],
      companyRules: [
        (v: string) => !!v || this.$i18n.t('validation-companyRequired'),
        (v: string) => new RegExp(schemas.signup.properties.company.pattern).test(v)
          || this.$i18n.t('validation-companyType'),
        (v: string) => new RegExp('.*\\D{2,}.*').test(v)
          || this.$i18n.t('validation-companyType'),
        (v: string) => (v && v.length <= schemas.signup.properties.company.maxLength)
          || this.$i18n.t('validation-companyMax'),
      ],
      passwordRules: [
        (v: string) => !!v || this.$i18n.t('validation-passwordRequired'),
        (v: string) => (v && v.length >= schemas.signup.properties.password.minLength)
          || this.$i18n.t('validation-passwordMin'),
        (v: string) => (v && v.length <= schemas.signup.properties.password.maxLength)
          || this.$i18n.t('validation-passwordMax'),
      ],
      checkBoxRules: [
        (v: unknown) => !!v || this.$i18n.t('validation-ToSAccept'),
      ],
    };
  },

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.passwordRepeat
        || this.$i18n.t('validation-passwordMatch');
    },
  },

  methods: {
    async sendSignUp() {
      // There is a validate method for the v-form component, but since it does not have a valid
      // TS type, it shows this as an error. Nevertheless, the code functions properly.
      if ((this.$refs.form as unknown as { validate(): boolean })?.validate()) {
        this.$signUp({
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phone,
          emailAddress: this.email,
          company: this.company,
        })
          .then(
            () => this.$changeRoute('Welcome'),
            () => { /* on failure */ },
          );
      } else {
        // If validation did not pass, focus on the first failed field
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        for (const field of this.$refs.form.$children) { // eslint-disable-line no-restricted-syntax
          if (field.errorBucket.length > 0) {
            field.$refs.input.focus();
            break;
          }
        }
      }
    },
  },
});
